import React, { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";

import faviconFallback from "../../static/public/favicon.png";

export interface FaviconProps {
  href: string;
}

/**
 * Do not directly use, only exported for the SpinnerFavicon component.
 * You should just use the Favicon component if wanting to override the current favicon.
 *
 * There can only be one favicon at a time (enforced by chrome)
 * so we share a global id so we can swap out the favicon in place
 * Additionally, elements added via react-helmet don't support refs
 * since it manually attaches elements to the head instead of using react's render
 * https://github.com/nfl/react-helmet/blob/1b57ddb1524bab195df3eeabafef67768a6d2a15/src/HelmetUtils.js#L425-L483
 * so we have to use this ID to get references to the favicon element instead
 */
export const FAVICON_ID = "favicon";

/**
 * Override the current favicon.
 *
 * You can have multiple of these mounted at a given time, the one
 * mounted the furthest down wins.
 */
export const Favicon: React.ComponentType<FaviconProps> = React.memo(
  function Favicon({ href }) {
    // HTML webpack plugin adds its own favicon, its not configurable
    // but we don't want to remove it from the raw HTML since its nice for initial load
    // so we remove it here after we've rendered the new favicon
    useLayoutEffect(() => {
      const badFaviconLinks = document.head.querySelectorAll(
        `link[rel="icon"]:not([id=${FAVICON_ID}])`,
      );
      badFaviconLinks.forEach((link) => document.head.removeChild(link));
    }, []);

    return (
      <Helmet>
        <link
          // we have to set this data prop so the react helmet will properly
          // swap the favicon in place
          // see https://github.com/nfl/react-helmet/issues/430
          data-react-helmet="true"
          href={href}
          id={FAVICON_ID}
          rel="icon"
          type="image/svg+xml"
        />
        <link
          href={faviconFallback}
          rel="alternate icon"
          // we should idealy swap this back to a .ico at somepoint, but chrome incorrectly handles fallback
          // see https://bugs.chromium.org/p/chromium/issues/detail?id=1162276 for more info
          type="image/png"
        />
      </Helmet>
    );
  },
);
