import { Intent } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components";

import { HexTag } from "../../hex-components/HexTag.js";
import { useHexFlag } from "../../util/useHexFlags.js";

interface ExploreBetaTagProps {
  intent?: Intent;
}

export const ExploreBetaTag: React.ComponentType<ExploreBetaTagProps> =
  React.memo(function ExploreBetaTag({
    intent = Intent.PRIMARY,
  }: {
    intent?: Intent;
  }) {
    const hideBetaTagsForDemo = useHexFlag(
      "product-launch-demo-hide-beta-flags",
    );
    if (hideBetaTagsForDemo) {
      return null;
    }

    return (
      <HexTag intent={intent} small={true}>
        Beta
      </HexTag>
    );
  });

export const AlphaTextWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
