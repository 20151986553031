import { Intent } from "@blueprintjs/core";
import React from "react";

import { HexTag } from "../../hex-components";

export interface CharacterCountIndicatorProps {
  className?: string;
  count: number;
  max: number;
  small?: boolean;
}

const SHOW_THRESHOLD = 0.5;
const WARNING_THRESHOLD = 0.8;
const ERROR_THRESHOLD = 1;

export const CharacterCountIndicator: React.ComponentType<CharacterCountIndicatorProps> =
  React.memo(function CharacterCountIndicator({
    className,
    count,
    max,
    small = false,
  }: CharacterCountIndicatorProps) {
    const proportion = count / max;
    if (proportion < SHOW_THRESHOLD) {
      return null;
    }

    const text = `${count}/${max}`;
    const intent =
      proportion > ERROR_THRESHOLD
        ? Intent.DANGER
        : proportion > WARNING_THRESHOLD
          ? Intent.WARNING
          : Intent.NONE;
    return (
      <HexTag className={className} intent={intent} small={small}>
        {text}
      </HexTag>
    );
  });
