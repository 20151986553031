import React from "react";

import { HexButton, HexNonIdealState } from "../../hex-components";
import { logErrorMsg } from "../../util/logging";
import { WarningIcon } from "../icons/CustomIcons";

export interface ErrorBoundaryProps {
  /**
   * Optional fallback to show in case of error.
   * If not provided, this component renders a reasonable default
   */
  fallback?: React.ReactNode;

  /**
   * Optional handler for errors.
   * Allows consumer to reset the boundry if the error is recoverable.
   */
  onError?: (err: Error, resetErrorState: () => void) => void;

  /**
   * Whether or not to send encountered error to datadog.
   * Defaults to true.
   * Should set this to false if the wrapped component is expected to error.
   */
  reportToDatadog?: boolean;

  /**
   * If specified, the 'try again button' will allow
   * the user to reload the entire page to attempt a recovery
   * rather than just trying to remount the component.
   *
   * Note, this will not be exposed if provided a fallback
   */
  reloadPageOnTryAgain?: boolean;
}

export interface ErrorBoundaryState {
  didCatchError: boolean;
}

/**
 * Simple wrapper around React's `componentDidCatch` API.
 * Makes it dead simple to add error boundries to the component tree.
 *
 * This API is only available to class components
 */
export class ErrorBoundary extends React.PureComponent<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  public static defaultProps: ErrorBoundaryProps = {
    reportToDatadog: true,
  };

  public override state: ErrorBoundaryState = {
    didCatchError: false,
  };

  public override componentDidCatch(
    err: Error,
    errInfo: React.ErrorInfo,
  ): void {
    const { onError, reportToDatadog } = this.props;

    this.setState({ didCatchError: true });

    if (reportToDatadog) {
      logErrorMsg(err, "Error boundary", {
        safe: { componentStack: errInfo.componentStack },
      });
    }

    if (onError !== undefined) {
      onError(err, this.resetErrorState);
    }
  }

  private resetErrorState = (): void => {
    this.setState({ didCatchError: false });
  };

  private tryAgain = (): void => {
    const { reloadPageOnTryAgain } = this.props;

    if (reloadPageOnTryAgain) {
      window.location.reload();
    } else {
      this.resetErrorState();
    }
  };

  public override render(): React.ReactNode {
    const { didCatchError } = this.state;
    const { children, fallback: errorView } = this.props;

    if (didCatchError) {
      if (errorView !== undefined) {
        return errorView;
      }

      return (
        <HexNonIdealState icon={<WarningIcon />} title="Something went wrong">
          <HexButton onClick={this.tryAgain}>Try again</HexButton>
        </HexNonIdealState>
      );
    }

    return <>{children}</>;
  }
}
