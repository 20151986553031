import { gql } from "@apollo/client";
import { Classes } from "@blueprintjs/core";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

import { HexTooltip } from "../../hex-components";
import { useOnClickProps } from "../../hooks/useOnClickProps";
import { ORG_ID } from "../../orgs";
import { Routes } from "../../route/routes";
import { Heading } from "../Heading";

import {
  WorkspaceAdminContactInfoFragment,
  useWorkspaceAdminsQuery,
} from "./ContactAnAdmin.generated";

const ContactText = styled.a`
  text-decoration: underline;
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 3px;
`;

const AdminList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const AdminEmail = styled.div`
  color: ${({ theme }) => theme.fontColor.MUTED};
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 3px 1px;

  border-top: 1px solid ${({ theme }) => theme.borderColor.DEFAULT};
`;

gql`
  fragment WorkspaceAdminContactInfo on User {
    id
    name
    email
  }
  query WorkspaceAdmins($orgId: OrgId!) {
    orgById(orgId: $orgId) {
      id
      workspaceAdmins {
        id
        ...WorkspaceAdminContactInfo
      }
    }
  }
`;

const stopPropagation: React.MouseEventHandler = (e) => {
  e.stopPropagation();
};
interface AdminInfoRowProps {
  email: WorkspaceAdminContactInfoFragment["email"];
  name: WorkspaceAdminContactInfoFragment["name"];
}

const AndminInfoRow = React.memo<AdminInfoRowProps>(function AdminInfoRow({
  email,
  name,
}) {
  return (
    <div>
      <div className={Classes.TEXT_OVERFLOW_ELLIPSIS}>{name}</div>
      <AdminEmail className={Classes.TEXT_OVERFLOW_ELLIPSIS}>
        {email}
      </AdminEmail>
    </div>
  );
});

export interface ContactAnAdminTooltipProps {
  readonly text?: string;
  usePortal?: boolean;
  children: JSX.Element;
  shouldStopPropagation?: boolean;
}

export const ContactAnAdminTooltip = React.memo<ContactAnAdminTooltipProps>(
  function ContactAnAdminTooltip({
    children,
    shouldStopPropagation = false,
    usePortal = true,
  }) {
    const askYourAdminClickProps = useOnClickProps({
      to: Routes.SETTINGS.getUrl({ subView: "users" }),
      target: "_blank",
      onClick: shouldStopPropagation ? stopPropagation : undefined,
    });

    const {
      data: adminData,
      error,
      loading,
    } = useWorkspaceAdminsQuery({ variables: { orgId: ORG_ID } });
    const workspaceAdmins = useMemo(
      () => adminData?.orgById.workspaceAdmins ?? [],
      [adminData],
    );

    return (
      <HexTooltip
        content={
          <TooltipContent>
            <Heading renderAs="h5">Admins</Heading>
            <AdminList>
              {workspaceAdmins.map((item) => (
                <AndminInfoRow
                  key={item.id}
                  email={item.email}
                  name={item.name}
                />
              ))}
            </AdminList>
            <Footer>
              <a {...askYourAdminClickProps}>View all users</a>
            </Footer>
          </TooltipContent>
        }
        css={`
          padding: 0;
        `}
        disabled={error != null || loading}
        interactionKind="hover"
        scrollContent={true}
        usePortal={usePortal}
      >
        {children}
      </HexTooltip>
    );
  },
);

export interface ContactAnAdminProps {
  readonly text?: string;
  usePortal?: boolean;
  shouldStopPropagation?: boolean | undefined;
}

export const ContactAnAdmin: React.FunctionComponent<ContactAnAdminProps> = ({
  shouldStopPropagation = false,
  text = "Contact an Admin",
  usePortal = true,
}) => {
  const handleTooltipTargetOnClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <ContactAnAdminTooltip
      shouldStopPropagation={shouldStopPropagation}
      usePortal={usePortal}
    >
      <ContactText onClick={handleTooltipTargetOnClick}>{text}</ContactText>
    </ContactAnAdminTooltip>
  );
};
