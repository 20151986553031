import { ConditionalKeyOf, OptionalFunction } from "@hex/common";
import React, {
  FocusEventHandler,
  FormEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  RefObject,
  createRef,
} from "react";
import styled from "styled-components";

import { DeleteKeys, Keys, ModKey } from "../../util/Keys";
import { shallowCompare } from "../../util/shallowCompare";

const ContentContainer = styled.span<{ isEditing: boolean }>`
  ${({ isEditing }) => (!isEditing ? "box-shadow: none;" : "")}
  min-width: 0;

  overflow: hidden;

  line-height: 14px;
  white-space: nowrap;

  text-overflow: ellipsis;

  ${({ isEditing }) => isEditing && `flex: none;`}
`;

interface ControlledContentEditableProps {
  id?: string;
  "data-cy"?: string;
  className?: string;
  content: string;
  isEditing: boolean;
  maxLength?: number;
  selectAllWhenEditingStarts?: boolean;
  onChange: (newValue: string) => void;
  onCancel: () => void;
  onSave: (newValue: string) => void;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  onBlur?: FocusEventHandler<HTMLSpanElement>;
  onFocus?: FocusEventHandler<HTMLSpanElement>;
  /**
   * @deprecated
   * This doesn't actually fire on key press, but don't want to remove it and break usage
   */
  onKeyPressDeprecated?: KeyboardEventHandler<HTMLSpanElement>;
  onKeyDown?: KeyboardEventHandler<HTMLSpanElement>;
  // Note when adding more callbacks here: they will get automatically skipped
  // in componentShouldUpdate - be sure to make a method below that calls the prop function
  // and pass that in to the ContentEditable, otherwise it will be out of date
}

/**
 * ContentEditable has nice properties (like growing to fit the text) but really hates to be controlled.
 * This needs to be a class so we can block updates when the props content matches the current content,
 * otherwise the cursor gets reset. We also need to maniulate the ref directly to force the
 * contenteditable div to update.
 */
export class ControlledContentEditable extends React.Component<ControlledContentEditableProps> {
  private contentRef: RefObject<HTMLDivElement>;

  constructor(props: ControlledContentEditableProps) {
    super(props);
    this.contentRef = createRef();
  }

  override shouldComponentUpdate(
    nextProps: ControlledContentEditableProps,
  ): boolean {
    /**
     * This object selects the keys that we want to skip when comparing prop equality.
     * Since it's super easy for functions to change (and thus reset the cursor), we
     * want them skipped by default. This type accomplishes that by asserting that all
     * keys of type function in ControlledContentEditableProps must be assigned the value
     * true, which we then use to filter.
     */
    const shouldSkip: Record<keyof ControlledContentEditableProps, boolean> &
      Record<
        Exclude<
          ConditionalKeyOf<ControlledContentEditableProps, OptionalFunction>,
          undefined
        >,
        true
      > = {
      id: false,
      "data-cy": false,
      className: false,
      // Note: we want to skip content for this check because we handle it further down
      content: true,
      isEditing: false,
      maxLength: true,
      // We can skip this because we don't need the component to do anything if only this prop changes
      selectAllWhenEditingStarts: true,
      onChange: true,
      onCancel: true,
      onSave: true,
      onClick: true,
      onBlur: true,
      onFocus: true,
      onKeyPressDeprecated: true,
      onKeyDown: true,
    };

    const keysToSkip = Object.keys(shouldSkip).filter(
      (key) => shouldSkip[key as keyof ControlledContentEditableProps],
    );

    const propsEqual = shallowCompare(this.props, nextProps, keysToSkip);

    if (!propsEqual) {
      return true;
    }

    if (nextProps.content !== this.contentRef.current?.textContent) {
      return true;
    }

    return false;
  }

  override componentDidMount(): void {
    if (this.contentRef.current != null) {
      this.contentRef.current.textContent = this.props.content;

      if (this.props.isEditing) {
        this.contentRef.current.focus();

        if (this.props.selectAllWhenEditingStarts) {
          this.selectAllText();
        }
      }
    }
  }

  override componentDidUpdate(prevProps: ControlledContentEditableProps): void {
    if (this.contentRef.current != null) {
      this.contentRef.current.textContent = this.props.content;

      if (this.props.isEditing && !prevProps.isEditing) {
        this.contentRef.current.focus();

        if (this.props.selectAllWhenEditingStarts) {
          this.selectAllText();
        }
      }
    }
  }

  selectAllText = (): void => {
    this.contentRef.current?.focus();
    const selection = document.getSelection();
    if (selection != null && this.contentRef.current != null) {
      selection.removeAllRanges();
      selection.selectAllChildren(this.contentRef.current);
    }
  };

  keyDownHandler: KeyboardEventHandler<HTMLSpanElement> = (evt): void => {
    const maxLength = this.props.maxLength;
    const currentValue = evt.currentTarget.textContent || "";
    this.props.onKeyDown?.(evt);

    // do not allow the key event to trigger the field to be modified if the max has been reached.
    if (
      maxLength &&
      maxLength <= currentValue.length &&
      !DeleteKeys.includes(evt.key)
    ) {
      evt.preventDefault();
      return;
    }

    // Make sure we arn't stopping propagation of modifier keys
    if (evt.key !== Keys.SHIFT && evt.key !== ModKey) {
      evt.stopPropagation();
    }

    if (evt.key === Keys.ENTER) {
      this.props.onKeyPressDeprecated?.(evt);
      // Swallow newlines
      evt.preventDefault();
    }
  };

  keyPressHandler: KeyboardEventHandler<HTMLSpanElement> = (evt): void => {
    // Make sure we arn't stopping propagation of modifier keys
    if (evt.key !== Keys.SHIFT && evt.key !== ModKey) {
      evt.stopPropagation();
    }
  };

  keyUpHandler: KeyboardEventHandler<HTMLSpanElement> = (evt): void => {
    // Make sure we arn't stopping propagation of modifier keys
    if (evt.key !== Keys.SHIFT && evt.key !== ModKey) {
      evt.stopPropagation();
    }

    const { maxLength, onCancel, onChange, onSave } = this.props;
    const currentValue = evt.currentTarget.textContent || "";
    if (evt.key === Keys.ENTER) {
      // Enter
      // only allow the change to pass through if the content is under maxLength
      if (!maxLength || maxLength >= currentValue.length) {
        onChange(evt.currentTarget.textContent || "");
        onSave(currentValue);
      } else {
        onCancel();
      }
    } else if (evt.key === Keys.ESCAPE) {
      // Escape
      onCancel();
    }
  };

  onInput: FormEventHandler<HTMLSpanElement> = (evt): void => {
    const { onChange } = this.props;

    const currentValue = evt.currentTarget.textContent || "";
    onChange(currentValue);
  };

  // These are reference stable wrappers around some functions that get passed in
  // so we don't need to rerender the content container to get prop updates
  onBlur: FocusEventHandler<HTMLSpanElement> = (evt): void => {
    // Blur is called when we click out of the HTML element
    // If Blur is called when we are over the word limit, discard the most recent changes
    const { maxLength, onCancel } = this.props;
    if (maxLength && maxLength < this.props.content.length) {
      onCancel();
    }
    this.props.onBlur?.(evt);
  };

  onClick: MouseEventHandler<HTMLSpanElement> = (evt): void => {
    this.props.onClick?.(evt);
  };

  onFocus: FocusEventHandler<HTMLSpanElement> = (evt): void => {
    this.props.onFocus?.(evt);
  };

  override render(): JSX.Element {
    const { className, content, "data-cy": dataCy, id, isEditing } = this.props;
    return (
      <ContentContainer
        ref={this.contentRef}
        className={className}
        contentEditable={isEditing}
        data-cy={dataCy}
        id={id}
        isEditing={isEditing}
        spellCheck={false}
        suppressContentEditableWarning={true}
        tabIndex={0}
        title={content}
        onBlur={this.onBlur}
        onClick={this.onClick}
        onFocus={this.onFocus}
        onInput={this.onInput}
        onKeyDown={this.keyDownHandler}
        onKeyPress={this.keyPressHandler}
        onKeyUp={this.keyUpHandler}
      />
    );
  }
}
