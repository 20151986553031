import { type DocsLinkValue } from "@hex/common";
import React, { ReactNode } from "react";
import styled from "styled-components";

export const Link = styled.a`
  ${({ theme }) => theme.resets.a}

  :link,
  :hover,
  :visited,
  :active {
    color: ${({ theme }) => theme.fontColor.LINK};
  }

  &:hover {
    text-decoration: underline;
  }
`;

interface DocsLinkProps {
  className?: string;
  children: ReactNode;
  to: DocsLinkValue | string;
  onMouseDown?: React.MouseEventHandler<HTMLElement>;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const DocsLink: React.FunctionComponent<DocsLinkProps> = ({
  children,
  className,
  onClick,
  onMouseDown,
  to,
}) => {
  return (
    <Link
      className={className}
      href={to}
      rel="noopener noreferrer"
      target="_blank"
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {children}
    </Link>
  );
};
