import { rgba } from "polished";
import React from "react";
import styled from "styled-components";

import { HexTag, HexTooltip } from "../../hex-components";

export const BetaTag = styled(HexTag)`
  &&& {
    color: ${({ theme }) => theme.highlightColor};
    font-weight: ${({ theme }) => theme.fontWeight.NORMAL};

    background-color: ${({ theme }) => rgba(theme.highlightColor, 0.12)};
    border-radius: ${({ theme }) => theme.borderRadius};
  }
`;

interface RBetaTagProps {
  className?: string;
}

export const RBetaTag: React.FunctionComponent<RBetaTagProps> = ({
  className,
}) => {
  return (
    <HexTooltip
      className={className}
      content="R projects are in Beta and may have missing functionality."
      placement="bottom"
    >
      <BetaTag
        css={`
          margin: -2px 0;
        `}
      >
        Beta
      </BetaTag>
    </HexTooltip>
  );
};
